import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section>
        <div className="z-10 flex w-full items-center justify-between px-4 pt-8 md:absolute md:px-10">
          <Link to="/">
            <StaticImage
              src="../images/Global/Logo-Dark.png"
              alt="Right Start Mortgage Logo"
              loading="eager"
              width={189}
            />
          </Link>

          <Link
            to="/"
            className="group no-underline md:flex md:h-14 md:w-14 md:items-center md:justify-center md:rounded-full md:bg-white/75"
          >
            <i className="far fa-times text-2xl text-black transition-all duration-300 ease-linear group-hover:text-primary-500"></i>
          </Link>
        </div>

        <div className="relative grid text-center md:h-screen md:grid-cols-2">
          <div className="flex items-center justify-center bg-white py-24 px-6 md:py-16 md:px-8 lg:px-12">
            <div>
              <p>
                <div className="mb-5 font-heading text-sm font-bold uppercase tracking-wide text-gray-400">
                  I want to
                </div>{" "}
                <span className="font-heading text-mobile-6xl font-bold text-secondary-500 md:text-7xl">
                  Purchase
                </span>
                <br />{" "}
                <span className="font-heading text-mobile-6xl font-bold text-gray-900 md:text-7xl">
                  a Home
                </span>
              </p>
              <ButtonSolid href="/purchase/" text="Purchase" altStyle={2} />
            </div>
          </div>

          <div className="relative flex items-center justify-center bg-primary-50 py-24 px-6 md:py-16 md:px-8 lg:px-12">
            <div>
              <p>
                <div className="mb-5 font-heading text-sm font-bold uppercase tracking-wide text-gray-400">
                  I want to
                </div>{" "}
                <span className="font-heading text-mobile-6xl font-bold text-primary-500 md:text-7xl">
                  Refinance
                </span>
                <br />{" "}
                <span className="font-heading text-mobile-6xl font-bold text-gray-900 md:text-7xl">
                  my Home
                </span>
              </p>
              <ButtonSolid href="/refinance/" text="Refinance" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;
export default Page;
